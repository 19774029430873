<script>
import {
  computed, onMounted, ref,
} from '@vue/composition-api'
import { MODIFY_TYPE_MAP } from '@core/utils/constant'
import { useMessage } from '@/hooks/useMessage'
import { getProjectInfo, saveProject } from '../api'

import useForm from './useForm'

export default {
  name: 'Add',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    showType: {
      type: String,
      default: '',
    },
    currid: {
      type: String,
      default: '',
    },
    systemTypeOpts: {
      type: Array,
      default: () => [],
    },
    managerOpts: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const showDetail = computed(() => props.value)
    const formRef = ref()
    const formData = ref({})
    const formConfigNotByAdd = ref([])
    const loading = ref(false)
    const formDataOld = ref({})

    const { message } = useMessage()

    const isAdd = computed(() => props.showType === MODIFY_TYPE_MAP.ADD)

    const canEdit = computed(() => props.showType !== MODIFY_TYPE_MAP.VIEW)
    const title = computed(() => {
      if (props.showType === MODIFY_TYPE_MAP.ADD) {
        return '新增项目'
      }

      if (props.showType === MODIFY_TYPE_MAP.EDIT) {
        return '编辑项目'
      }

      return '项目详情'
    })

    const handleClose = () => {
      emit('input', false)
      emit('refresh')
    }

    const handleInput = value => {
      emit('input', value)
      if (!value) {
        emit('refresh')
      }
    }

    // 取消
    const handleCancel = () => {
      emit('input', false)
    }

    // 判断推进计划是否改变
    const checkPlanTimeChange = computed(() => form => {
      if (form.planStartAt === formDataOld.value.planStartAt && form.planEndAt === formDataOld.value.planEndAt) {
        return false
      }

      return true
    })

    // 提交
    const handleSubmit = async () => {
      const valid = formRef.value.validate()
      if (valid) {
        // 验证通过执行提交
        const form = formRef.value.getFormData()

        try {
          const {
            belongsTo, ...others
          } = form
          const { rdPromiseMonth, rdPromise, rdPromiseState } = formData.value
          const { data: { status, message: messageText } } = await saveProject({
            belongsTo: belongsTo.join(','),
            pmUserName: props.managerOpts.find(x => x.userId === form.pmUserId)?.name || '',
            id: isAdd.value ? '' : props.currid,
            rdPromiseMonth: checkPlanTimeChange.value(form) ? '' : rdPromiseMonth,
            rdPromise: checkPlanTimeChange.value(form) ? '' : rdPromise,
            rdPromiseState: checkPlanTimeChange.value(form) ? '' : rdPromiseState,
            ...others,
          })
          if (status === 200) {
            // 执行保存事件
            message.success('保存成功！')
            handleClose()
          } else {
            message.error(messageText)
          }
        } catch (err) {
          message.error(err)
        }
      }
    }

    const fetchInfo = async () => {
      loading.value = true
      try {
        const { data: { data = {}, status, message: messageText } } = await getProjectInfo({ versionNum: props.currid })
        if (status === 200) {
          formData.value = data
          formDataOld.value = { ...data }
          const { formConfig } = useForm({ systemTypeOpts: props.systemTypeOpts, managerOpts: props.managerOpts, formData: formData.value })
          formConfigNotByAdd.value = formConfig.value
        } else {
          message.error(messageText)
        }
        loading.value = false
      } catch (err) {
        loading.value = false
        message.error(err)
      }
    }

    onMounted(() => {
      if (!isAdd.value) fetchInfo()
    })

    return {
      title,
      isAdd,
      canEdit,
      formRef,
      showDetail,
      loading,
      formConfigNotByAdd,
      ...useForm({ systemTypeOpts: props.systemTypeOpts, managerOpts: props.managerOpts }),
      handleClose,
      handleInput,
      handleCancel,
      handleSubmit,

    }
  },
}
</script>
<template>
  <v-navigation-drawer
    fixed
    :temporary="showDetail"
    touchless
    right
    stateless
    :width="500"
    :value="showDetail"
    @input="handleInput"
  >
    <v-card
      height="100%"
      class="drawer-wrap"
    >
      <div class="drawer-header">
        <h4 class="detail-title">
          {{ title }}
        </h4>
        <v-spacer />
        <v-btn
          icon
          small
          @click="handleClose"
        >
          <v-icon size="22">
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <!--表单区域-->
      <div class="drawer-main">
        <XwForm
          v-if="!loading"
          ref="formRef"
          :form-config="isAdd ? formConfig: formConfigNotByAdd"
          :can-edit="canEdit"
        >
          <template #expectOnlineAt>
            <span style="display: inline; font-size: 12px">若为倒排期项目请务必填写</span>
          </template>
        </XwForm>
      </div>
      <div class="drawer-footer">
        <div class="drawer-btns">
          <v-btn
            v-if="canEdit"
            color="primary"
            outlined
            class="btn-item"
            @click="handleCancel"
          >
            取消
          </v-btn>
          <v-btn
            v-if="canEdit"
            color="primary"
            class="btn-item"
            @click="handleSubmit"
          >
            提交
          </v-btn>
          <v-btn
            v-if="!canEdit"
            color="primary"
            class="btn-item"
            @click="handleCancel"
          >
            确定
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-navigation-drawer>
</template>
<style lang="scss" scoped>
.drawer-wrap{
  display: flex;
  flex-direction: column;
  .drawer-header{
    display: flex;
  }
  .drawer-main{
    flex: 1;
    position: absolute;
    width: 100%;
    top: 60px;
    bottom: 68px;
    overflow-y: scroll;
  }
  .drawer-footer{
    position: absolute;
    bottom: 0;
    padding: 10px 0;
    width: 100%;
    height: 68px;
    background-color: rgba(19, 17, 32, 0.04);
    .drawer-btns{
      margin-left: auto;
      padding: 6px 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn-item{
        margin-right: 8px;
      }
    }
  }
}
</style>
